import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import { forwardRef } from 'react';

import type { TamagoshiIcon } from '@/core/tamagoshiv2/interfaces';

export const InfoOutlineIcon = forwardRef<SVGSVGElement, TamagoshiIcon>(
  (props, ref) => (
    <Svg
      optimizationId="tama-info-outline"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M11 7H13V9H11V7ZM12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="currentColor"
      />
    </Svg>
  ),
);

InfoOutlineIcon.displayName = 'InfoOutlineIcon';
