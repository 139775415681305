import { select } from 'redux-saga/effects';

import type { UserSegments } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

import { userSegmentsSelector } from '../commercialAnimation.selectors';

export function* getUserSegments(): Generator<any, UserSegments, any> {
  const userSegments: UserSegments = yield select(userSegmentsSelector);
  return userSegments;
}
