import { useEffect, useMemo } from 'react';
import { v4 } from 'uuid';

import logger from '../../observability/logger';
import { IS_LOCAL } from '../../platform/constants';
import { useStoreManager } from './useStoreManager';

export function useDynamicSaga(
  key: string,
  saga: () => Generator<unknown, any, unknown>,
): void {
  const { storeManager } = useStoreManager();
  const subscriberId = useMemo(v4, []);

  useEffect(() => {
    const isInjected = storeManager?.isSagaInjected(key);

    if (IS_LOCAL) {
      const {
        isApplicationStateWarningsEnabled,
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      } = require('@/devTools/DevTools/components/Performance/applicationStateWarnings');

      if (isApplicationStateWarningsEnabled() && isInjected) {
        logger.error(
          Error(
            `Saga with key "${key}" has already been registered. This useDynamicSaga() call could be avoided.`,
          ),
        );
      }
    }

    if (!isInjected) {
      storeManager?.requireSaga(key, saga, subscriberId);
    }

    return () => {
      if (storeManager?.isSagaInjected(key)) {
        storeManager?.releaseSaga(key, subscriberId);
      }
    };
  }, [key, saga, storeManager, subscriberId]);
}
