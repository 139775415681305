import { useSelector } from 'react-redux';

import type { SpartacuxTrackingState } from '../tracking.state';

interface VisitorId {
  visitorId: string;
}

export const useVisitorId = () =>
  useSelector<SpartacuxTrackingState, VisitorId>(
    (state) => state.tracking.user,
  );
